import React from "react";
import { arrayOf, string } from "prop-types";
import Section from "../Base/Section";
import Heading from "../Base/HeadingBuilder";
import Image from "../../components/ImageQuerys/LargeFeaturesImages";
import check from "../../images/svg/checkmark.svg";

const BulletsWithHeroImage = ({
  className,
  heading,
  paragraphs,
  bulletsTitle,
  bullets,
  imageName,
}) => {
  return (
    <Section fullWidth fullWidthCarousel className={className}>
      <div
        className={`
        max-w-[1360px] mx-auto
        px-4 pt-20 lg:px-20 lg:pt-20
        pb-[150px] md:pb-[200px] lg:pb-[300px]
        bg-primary-30 xl:rounded-[24px]
      `}
      >
        <div
          className={`
        flex flex-col md:flex-row justify-between items-end
        gap-10 lg:gap-16 xl:gap-[120px]
        `}
        >
          <div className="w-full">
            {heading && (
              <Heading level={3} injectionType={3} withBlueSymbol className="">
                {heading}
              </Heading>
            )}

            <div>
              {paragraphs.map((p) => (
                <p key={p} className="text-p text-black-300 mt-2">
                  {p}
                </p>
              ))}
            </div>
          </div>

          <div className="w-full">
            {bulletsTitle && (
              <div className="text-black-300 text-p mb-6">{bulletsTitle}</div>
            )}
            <ul className="pl-0 list-none">
              {bullets.map((bullet) => (
                <li
                  key={bullet}
                  className="text-black text-p mt-6 flex items-center"
                >
                  <img alt="checkmark" src={check} className="mr-4" />
                  <span>{bullet}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Image
        imageName={imageName}
        className={`
          max-w-[1120px] mx-auto
          mt-[-100px] md:mt-[-150px] lg:mt-[-250px]
        `}
      />
    </Section>
  );
};

BulletsWithHeroImage.propTypes = {
  className: string,
  heading: string,
  paragraphs: arrayOf(string).isRequired,
  bulletsTitle: string,
  bullets: arrayOf(string).isRequired,
  imageName: string.isRequired,
};

BulletsWithHeroImage.defaultProps = {
  className: "",
  heading: null,
  bulletsTitle: null,
};

export default BulletsWithHeroImage;
